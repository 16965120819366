import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '../components/layout/Layout';
import Seo from '../components/Seo';
import HomeLayout from '../pagesContent/HomeLayout';


const IndexPage = ({ location }: PageProps) => (
  <Layout
    location={location}
    noNavbarMargin
  >
    <Seo
      pageType="home"
      title="homePage"
      keywords={[]}
      lang="pl"
    />
    <HomeLayout />
  </Layout>
);

export default IndexPage;
